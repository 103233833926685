/* Gen Info Accordion */

.market-value-accordion-panel {
    margin: 10px 0px 10px 0px;
    background: #FFFFFF !important;
    border: 2.5px solid #006633 !important;
    border-radius: 12px !important;
    overflow:hidden;

}

.market-value-accordion-heading {
    align-items: center;
    padding: 17px 0px;
    width: 100%;
    height: 94px;
}

.market-value-accordion-heading > button {  
    display: inline-flex;
    align-items: center; 
    /*line-height: 30px;*/
    width: 100%;
    background-color: #FFFFFF !important;
}

.usa-accordion__button > span {
    margin-left:12px;
    font-weight: 100;
}

.market-value-accordion-content {
    padding: 50px 20px !important;
    border-top: 1.5px solid #9BB672 !important;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}

.accordion-icon {
    height: 32.25px;
    width: 32.25px;
    color: #538200;
}

.market-value-accordion-heading > button {
    background-image:url("/assets/img/angle-arrow-up.png"), linear-gradient(transparent, transparent) !important;
}

.market-value-accordion-heading > button[aria-expanded=false]{
    background-image:url("/assets/img/angle-arrow-down-green.png"), linear-gradient(transparent, transparent) !important;
}

.market-value-accordion-heading > button[aria-expanded=false]:hover{
    background-image:url("/assets/img/angle-arrow-down-hover.png"), linear-gradient(transparent, transparent) !important;
}

.input {
    background: #F3FBFA;
}
.header-Adjustment{
  padding:29px 15px;
  position: relative;
    top: -16px;
}

.radio-toggle-switch {
    width: 131.56px;
    height: 26.63px;
    padding: 7.24px, 5.32px, 7.87px, 5.76px;
    border-radius: 34.54px;
    

}

.radio-toggle-switch-active {
    width: 131.56px;
    height: 26.63px;
    padding: 7.24px, 5.32px, 7.87px, 5.76px;
    border-radius: 4.99px;
    background: #FFFFFF;
    border: 0.36px solid #0000000A;
    box-shadow: 0px 2.1590206623077393px 0.7196735739707947px 0px #0000000A;

box-shadow: 0px 2.1590206623077393px 5.757388591766357px 0px #0000001F;

}

.input-full{
    width:90% !important;
}

.input-icon {
    position: relative;
  }
  
  .input-icon > i {
    position: absolute;
    display: block;
    transform: translate(0, -50px) !important;
    top: 62px !important;  
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
  }

  .colon{
    display:none;
  }
  
  .input-number {
    padding-left:20px;
  }
  
  .input-icon-right > i {
    right: 0;
  }
  
  .input-icon-right > input {
    padding-left: 0;
    padding-right: 25px;
    text-align: right;
  }

.usa-input:disabled {
    margin-top: 0 !important;
    background: #F3FBFA;
}

  #appraisal-total-group{
   
    /* Section */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.74571px 16px;
    width: 274px;
    height: 77px;
    background: #FFFFFF;
    border: 1px solid #9BB672;
    /* Button Drop Shadow */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36);
    border-radius: 5.99314px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    margin-top: 50px;
    margin-bottom: 50px;

  }

  #atg-legend{
    margin-top: 0px !important;
    font-size: 18px !important;
  }


button:focus-visible:not(.mat-button-toggle-button){
    outline-offset: 0;
}

.market-value-accordion-panel:has(h4):has(.usa-accordion__button:focus-visible):not(.cols .mat-button-toggle-button,.mat-button-toggle-button){
  outline:solid 4px #006633!important;
}



.usa-form-group .usa-input:focus-visible,
.usa-form-group .input:focus-visible
.usa-form-group .input:focus-visible{
  outline:2px solid #006633 !important;
    outline-offset: 0;
    
}



label:not(.usa-label,.mdc-form-field>label, li > label){
  box-sizing: border-box;
  padding: 17px;
  position: absolute;
  width: 91%;
  top: 1px;
  left: .5px;
  text-align: left;  
  max-height:39px;
}
/*Dual ListBOX */
.dual-list .button-bar button {
  width: 47%;
  display: none !important;
}
.dual-list{
  position:relative !important;
  display:flex;
  gap:25px;
}
.record-picker >ul{
  min-height:250px !important;
}

button.btn-block {
  display: block;
  width: 100%;
  margin-bottom: 8px;
 font-size: 0;/* */
}

.point-right:after {
  content: "\25b6";
  padding-left: 0em !important;
  font-size:15px !important;
  color:#000 !important;
}
.point-left:before {
  content: "\25c0";
  padding-right: 0em !important;
  font-size:15px !important;
  color:#000 !important;
}

.listbox > .btn-primary{
  background:transparent;
  border:0;
}

button.btn-block{
  position:relative;
  max-width: fit-content;
  display: block;
  width: 100%;
  margin-bottom: 8px;
 font-size: 0;
}

 dual-list > div > div:nth-child(1) > button{
  position:absolute;
  top: 50%; 
  right: 50%;
  transform: translate(50%,-50%);
}

#modal-3 > div.app-modal > div > div > div > dual-list > div > div:nth-child(2) > button{
  position:absolute;
  top: 30%; 
  right: 50%;
  transform: translate(50%,-50%);
}
/*Dual ListBOX */

/*used to put gap between accordion and estimator*/
#accordion-row.grid-row{
  gap:45px;
  justify-content: center;
}

#estimator{
  width:37% !important;
}


.accordion-grid-container{
  padding-left:0 !important;
}

.accordion-grid-row{
  gap:15px;
}

.estimator-inner{
  position:relative;
}

.estimator-inner .estimator-inner-value{
  position:absolute;
  bottom: 5px;
  left:0;
  right:0;
}

@media only screen and (max-width:1272px){
  .usa-button label{
    font-size: calc(12px + 0.390625vw);
  }

  /*.accordion-icon {
    height: 50px !important;
    width: 50px !important;
    color: #538200;
  }*/
}

@media only screen and (max-width:1212px){
  .usa-label-number-inputs{
    display:block!important;
    width:100%
  }

  
  
}


@media only screen and (max-width:1032px){

  .market-value-accordion-panel {
    margin: 00px 0px 10px 0px !important;
    background: #FFFFFF !important;
    border: 2.5px solid #006633 !important;
    border-radius: 12px !important;
    overflow:hidden;
  }

  [class*=cols] {
    position: relative;
    box-sizing: border-box;
    padding: 0 5px 0 0;
    display: block!important;
  }

[class*=inputs] {
  position: relative;
  box-sizing: border-box;
  display: block!important;
}

.grid-container{
  padding: 0 !important;
}

  .is-invalid[_ngcontent-ng-c2975010672] {
    outline-offset: 0;
    font-size: 12px;
  }
  #main-accordion-wrapper{
    box-sizing: border-box;
    padding-left:0 !important;
    padding-right:0 !important;
    width:100%;
  }
  .mat-button-toggle-standalone, .mat-button-toggle-group {
    position: relative;
    display: inline-flex;
    flex-direction: column !important;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateZ(0);
    border-radius: var(--mat-legacy-button-toggle-shape);
  }
  .usa-button-toggle .mat-button-toggle-appearance-standard {
    width: 100% !important;
    display:block;
    margin: 1px;
 }

 .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
    box-sizing: border-box;
    border: 0px solid rgba(0, 0, 0, 0.1) !important;
    padding: 2px !important;
    background: #f5f5f6;
    width: 100%;
 }

  #accordion-row{
    display:flex;
    margin:0 !important;
  }
  
  #accordions{
    -webkit-order:2;
    order:1 !important;
    width:100%;
    min-width:350px !important;
    margin:0 !important;
    padding-left:20px !important;
    padding-right:20px;
  }
  #estimator{
    -webkit-order:1;
    padding:15px;
    order:2 !important;
    width:100% !important;
  }
  .estimator >.grid-row{
    width:100%;
    margin:0;
    border-radius: 5px;
  }
  .estimator >.grid-row > .grid-col-12{
    border-radius:8px;
  }
  .estimator{
    padding:0 !important;
    margin-left:0 !important;
    gap:0 !important;
    background-color:#9BB672 !important;
    width:100%;
    min-width: none !important;
  }
  .estimator-inner-top{
    position:relative;
    height:40px !important;
    padding:0;
    display:flex;
    gap:10px;
    justify-content: center;
    align-items:center;
    border-radius: 10.47px;
    background:#fff;
    margin-bottom:8px;
  }

  .estimator-inner{
    position:relative;
    height:84px !important;
    margin-bottom:0 !important;
    max-width: 100% !important;
    margin-left:0 !important;
    margin-right:0 !important;
    border-radius:0 !important;
    background-color:#fff;
    padding-bottom:2px !important;
    border:5px !important;
  }

  

  .appraisalgroup-wrapper{
    position:relative;
    display:flex !important;
    box-sizing: border-box !important;
    justify-content: space-between;
  }

  .appraisalgroup-wrapper .grid-col-6{
     width:49% !important;
  }

  .estimator-title, .estimator-value{
    font-family: Roboto, sans-serif;
    font-size: 16.96px !important;
    font-weight: 600 !important;
    line-height: 19.88px;
    text-align: left;
    margin-bottom:0 !important;
    margin-top: 0 !important;
    display:flex;
  }

 
  
  .colon{
    display:block;
  }

  .estimatior_spacing{
    padding-top:0 !important;
 }

  #accordion-row.grid-row{
    gap:0px;
    justify-content: center;
  }

  .accordion-grid-container{
    padding-left:0 !important;
    
  }

  .formInputs{
    width:100% !important;
    display:block !important;
  }



  .appraisal-type {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size:14px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 10px;
    margin:5px;
    width: 100% !important;
    max-width:100%!important;
    height: 45px;
    background: #F8F8F8;
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.36) !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
 } 

    .usa-input,.usa-select{
      display:block;
    }

    .input-full {
      width: 100% !important;
    }
    
    .input-icon > input[type=number]:disabled {
      width: 100% !important;
    }


    
    /*accordions*/
    .market-value-accordion-panel{
      overflow: hidden !important;
    }

    .usa-label-number-inputs{
        display:block!important;
        width:100%
    }
 
}

@media only screen and (max-width:670px){
  .usa-button label{
    font-size: calc(11px + 0.390625vw);
  }
}

@media only screen and (max-width:634px){
  #main-accordion-wrapper{
    label[_ngcontent-ng-c3756492533]:not(.usa-label) {
      box-sizing: border-box;
      font-size:3vw;
      position: absolute;
      width: 91%;
      top: 1px;
      left: 0.5px;
      text-align: left;
      max-height: 39px;
    }


    label{
      box-sizing: border-box;
      font-size:3vw;
      width: 91%;
      top: 1px;
      left: .5px;
      text-align: left;  
      max-height:39px;
    }

    .estimator-inner{
      
      border-radius:5px !important;
    }

    .colon{
      display:block;
    }

    .estimator_spacing{
      padding-top:0px !important;
    }
    
  }
  
}
@media (max-width: 764px) and (min-width: 640px) {
  .usa-button {
      font-size: initial !important;
  }
}
@media (max-width: 950px) {
  .usa-button {
      font-size: initial !important;
  }
}


.accordion-button-details {
  background-color: #E5F6CB; 
  font-size: 12px; 
  padding: 0px 5px; 
  border-radius: 10px; 
  margin-left: 20px;
  width:320px;
  max-width:100%;
  line-height: 1.8em;
}
