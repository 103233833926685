
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$NationalTEA-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$NationalTEA-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$NationalTEA-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$NationalTEA-theme: mat.m2-define-light-theme((
  color: (
    primary: $NationalTEA-primary,
    accent: $NationalTEA-accent,
    warn: $NationalTEA-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($NationalTEA-theme);

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");


html, body { height: 100%; }
html{
    scroll-behavior: smooth !important;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
    font: 400 14px / 20px Roboto, sans-serif !important;
    letter-spacing: .0178571429em;
}

h2.usa-main-page-header{
    color:#3D4551 !important;
    margin-bottom:25px;
    font-family: "Roboto", sans-serif !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
}

h4.subtitle {
    position: relative;
    top: -15px;
    color: #3D4551;
    font-family: "Roboto", sans-serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 16px;
}
.cols .mat-button-toggle-button{
    position:relative;
    display:flex;
    align-items:center;
    top: -10px;
    border: 0;
    border-radius:4px!important;
    color: inherit;
    padding: 5px;
    font: inherit;
    outline: none;
    height:45px;
    max-width: 100%;
    cursor: pointer;
    
}
a.resetLink:focus-visible{
    border:2px solid #006633 !important;
    outline:0 !important;
}
.mat-button-toggle-button{
    padding:6px 0 !important;
}


.totalvolume .mat-button-toggle{
    width:88px !important;
}

.totalvolume{
    position:relative;
    top:-30px;
}

.usa-label > small{
    font-weight: 600 !important;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 99999;
}



.usa-button-toggle .mat-button-toggle-appearance-standard{
    color:#3D4551;
    border-radius: 4px !important;
    background-color: #F0F0F0 !important;
}

.usa-button-toggle .mat-button-toggle-checked{
    background-color:rgb(255,255,255,0.87) !important;
    border-radius: 4px !important;
    box-shadow: 0 1px 3px rgba(154, 42, 42, 0.12), 0 1px 2px rgba(0,0,0,0.24)!important;
}

.usa-button-toggle .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color:#fff;
    border-radius: 4px !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24)!important;
}
.mat-button-toggle-appearance-standard 
.mat-button-toggle-label-content{
   line-height:1.5em !important;
}
.mat-button-toggle .mat-pseudo-checkbox {
    margin-right: 12px;
    display: none!important;
}


.mat-button-toggle-focus-overlay{
    background-color: transparent !important;
}




.mat-button-toggle .mat-button-toggle-ripple {
    display: none;
}


.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: 400;
padding: 0 5px!important;
line-height: var(--mat-standard-button-toggle-height);
}

.mat-mdc-slider .mdc-slider__value-indicator{
    opacity: 1 !important;
}

.market-value-accordion-panel > h4 > button{
    position: relative;
    box-sizing: border-box;
    top: -16px;
    height: 92px;
}

.usa-form .usa-input:focus-visible, 
.usa-form .usa-range:focus-visible, 
.usa-form .usa-select:focus-visible, .usa-input:focus-visible,.usa-input:focus{
    outline:2px solid #006633 !important;
    outline-offset: 0;
}



.form-control:focus{
    border:0 !important;
    outline: 0;
    box-shadow: none !important;;
}

.cols .mat-button-toggle{
    position:relative !important;
    display:flex !important;
    flex-direction: column;
    text-align:left;
    margin-bottom:12px !important;
}

.cols .mat-button-toggle-button {
    width:100% !important;
 }
 .cols .mat-button-toggle-label-content{
    width:100% !important;
 }

 :host{
    .mat-button-toggle-focus-overlay{
        display:none !important;
    }
 }

 .material-icons{
   font-size:15px!important
 }

 
 .material-icons:not(.mat-mdc-tooltip-disabled):focus-visible{
    outline:2px solid #006633 !important;
    outline-offset:0;
}





.mat-mdc-tooltip .mdc-tooltip__surface{
    position:relative!important;
    font-size:.9rem!important;
    font-weight:100!important;
    line-height: 1.5em !important;
    background:#000 !important;
    max-width:300px !important;
    min-height:50px;
    padding:6px;
    width:100% !important;
}

#forestCode > div > div > div.ng-input{
    height:50px !important;
}

/*====================================*/
/*   Material Tooltip                 */
/*====================================*/
.mat-mdc-tooltip{
    position:absolute;
    top:-4px;
    left:-134px;
    border:2px solid #000;
    background:#000;
    border-radius:4px;    
    -webkit-font-smoothing:antialiased !important;

    &::after{
        width:0;
        height:0;
        content:'';
        position:absolute;
        border-left:0.5rem solid transparent;
        border-right:0.5rem solid transparent;
        border-top:0.5rem solid #000;
        
    }
    &.below{
        overflow:initial;
        margin-bottom:1rem;
        &:after{
            position: absolute;
            bottom: -.5rem;
            left:50%;
            transform:rotate(0);
        }
    }
    &.above{
        overflow:initial;
        margin-bottom:1rem;
        &:after{
            left:-0.5rem;
            top:calc(50% - 0.5rem);
            transform:rotate(180deg);
        }
    }
}


.custom-radio-button.mat-mdc-tooltip{
    position:absolute;
    top:14px !important;
    left:-134px;
    border:2px solid #000;
    background:#000;
    border-radius:4px;    
    -webkit-font-smoothing:antialiased !important;
  
    &::after{
        width:0;
        height:0;
        content:'';
        position:absolute;
        border-left:0.5rem solid transparent;
        border-right:0.5rem solid transparent;
        border-top:0.5rem solid #000;
        
    }
    &.below{
        overflow:initial;
        margin-bottom:1rem;
        &:after{
            position: absolute;
            bottom: -.5rem;
            left:50%;
            transform:rotate(0);
        }
    }
    &.above{
        overflow:initial;
        margin-bottom:1rem;
        &:after{
            left:-0.5rem;
            top:calc(50% - 0.5rem);
            transform:rotate(180deg);
        }
    }
}





.mat-button-toggle-button:not(:disabled):focus-visible{
    border-radius: 3px !important;
    outline:2px solid #006633!important;
    outline-offset: 0px !important;
}

.usa-button-toggle.mat-button-toggle-appearance-standard:focus-visible{
    outline:2px solid #006633!important;
}




.cols .mat-button-toggle:has(:disabled),
.cols .mat-button-toggle:has(:disabled):hover{
    background-color:#F8F8F8!important;
    color:#575757 !important;
    border:1px solid transparent !important;
    cursor: not-allowed !important;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked:not(:disabled) {
    color: var(--mat-standard-button-toggle-selected-state-text-color);
    background-color: #E5F7CB;
   
}

.containerbtn:has(.mat-icon.mat-mdc-tooltip-disabled){
    cursor: not-allowed !important;
}

.mdc-form-field>label {
    margin-top:5px;
    margin-left: 0 !important;
    margin-right: 10px !important;
    padding-left: 4px;
    padding-right: 0;
    order: 1;
}

.mdc-switch:enabled .mdc-switch__track::before {
    background: #cccccc !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
   background-color:#9BB672 !important;
}

.mdc-switch .mdc-switch__track {
    height: 23px !important;
    border-radius:12px !important;
}

.mat-mdc-slide-toggle .mdc-switch {
    --mdc-switch-disabled-selected-handle-color: #fff !important;
    --mdc-switch-disabled-unselected-handle-color: #fff !important;
    --mdc-switch-unselected-handle-color: #fff!important;
    --mdc-switch-selected-handle-color: #fff !important;
    --mdc-switch-selected-focus-handle-color: #fff!important;
    --mdc-switch-selected-hover-handle-color: #fff!important;
    --mdc-switch-selected-pressed-handle-color: #fff !important;
    --mdc-switch-unselected-handle-color: #fff;
    --mdc-switch-unselected-focus-handle-color: #fff !important;
    --mdc-switch-unselected-hover-handle-color: #fff !important;
    --mdc-switch-unselected-pressed-handle-color: #fff !important;
    --mdc-switch-disabled-selected-track-color: lightgrey;
    --mdc-switch-disabled-unselected-track-color: white;
    --mdc-switch-selected-track-color: lightgrey;
    --mdc-switch-selected-focus-track-color: lightgrey;
    --mdc-switch-selected-hover-track-color: lightgrey;
    --mdc-switch-selected-pressed-track-color: lightgrey;
    --mdc-switch-unselected-track-color: white;
    --mdc-switch-unselected-focus-track-color: white;
    --mdc-switch-unselected-hover-track-color: white;
    --mdc-switch-unselected-pressed-track-color: white;
    --mdc-switch-disabled-selected-icon-color: #fff !important;
    --mdc-switch-disabled-unselected-icon-color: #fff !important;
    --mdc-switch-selected-icon-color: #fff !important;
    --mdc-switch-unselected-icon-color: #fff !important;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon{
    fill: #fff !important
}

.mdc-switch--selected .mdc-switch__icon--on, .mdc-switch--unselected .mdc-switch__icon--off{
    opacity: 0 !important;
}


button.saveChanges_btn, 
button.usa-button-green{
    text-align: center;
    font-family: 'Roboto', sans-serif !important;
    font-size: 15.25px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    border-radius:5px;
    height: 35px;
    margin-right:10px;
    cursor:pointer;
    outline:0;
    /*border:1px solid #063;*/
    background:#063;
    color:#fff;
}

button.usa-button-green:hover{
    background-color:#7A591A;
    color:#fff;
}

.content-container{
    position:relative;
}


button.closeBtn{
    position:absolute !important;
    top:-12px;
    right: -12px !important;
    display:flex;
    align-items: flex-start;
    justify-content: end;
    cursor: pointer;
    border:0;
    background:transparent;
    outline:2px solid transparent;
}

button.closeBtn:focus-visible{
    position:absolute !important;
    top:-12px;
    right: -12px !important;
    display:flex;
    align-items: flex-start;
    justify-content: end;
    cursor: pointer;
    border:0;
    outline:2px solid #006633 !important;
    background:transparent;
}


/*
.btn-success.focus{
    box-shadow:none !important;
    outline:2px solid #006633 !important;
}
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.btn:focus-visible:not(:disabled):not(.disabled) {
    cursor: pointer;
    outline: 2px solid #006633;
}

button.btn-block:focus{
    outline: 2px solid #006633 !important;
    background:none !important;
}
.record-picker li.selected:focus{
   border:1px solid #f00;
}

.record-picker li:focus{
    outline: 2px solid #006633 !important;
}*/
button.cancelLink{
    text-align: center;
    font-family: 'Roboto', sans-serif !important;
    font-size: 15.25px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    border-radius:5px;
    height: 35px;
    margin-right:10px;
    color:#063;
    cursor:pointer;
    border:0;
    background:transparent
 }

 


 button.cancelLink:focus-visible{
    color:#063;
    cursor:pointer;
    border:0;
    outline:2px solid #006633 !important;
    background:transparent
 }
 
 button.cancelLink:hover{
     text-decoration:underline;
     background:transparent;
     border:0;
 }

 button.saveChanges_btn:focus-visible{
    outline:2px solid #006633 !important;
    outline-offset: 2px;
 }
 
button:focus-visible{
    outline:2px solid #006633;  
}



.formControls input[type=radio]:focus-visible{
    outline:3px solid #538200 !important;  
}

#forestCode > div, 
#regionCode > div, 
#districtCode > div,
#treeProductId > div,
#treeSpeciesId > div
{
    width:100%;
    /*max-width:328px;*/
    min-width:fit-content;
    height:50px;
}

.ng-select.ng-select-single .ng-select-container {
    height: 50px !important;
    padding:0 10px;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border: 2px solid #006633 !important;
    box-shadow: none !important;
}

.ng-select div, .ng-select input, .ng-select span {
    box-sizing: border-box;
    color: #3D4551 !important;
}



.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    white-space: wrap !important; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.ng-value-icon .left{
    color: #df0009 !important;
}

.ng-value-icon .left:focus{
    outline:1px solid #006633 !important;
}

.ng-select .ng-clear-wrapper{
    position:relative;
    width:17px !important;
    height:17px !important;
}

.ng-select .ng-clear-wrapper:focus-visible {
    outline:1px solid #006633 !important;
}

.ng-select .ng-clear-wrapper .ng-clear {
    display: inline-block !important;
    font-size: inherit !important;
    position: relative !important;
    top: -5px !important;
    right: -3px !important;
    line-height: 1;
    pointer-events: none;
}




.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #575757 !important;
}


.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
  min-width:328px;
  height:50px !important;
  white-space: pre-wrap;
}

.ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: normal !important;
    white-space: pre-wrap;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal!important;
}


/*.ng-dropdown-panel {
    box-sizing: border-box;
    position: absolute;
    opacity: 0;
    width: 100%;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    max-height: 140px !important;
   overflow-y: hidden;
}
*/

.ng-dropdown-panel {
    top: 100% !important;
    box-sizing: border-box;
    position: absolute;
    opacity: 0;
    width: 100%;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    height:fit-content !important;
    
  }
.ng-select.ng-select-single{
    padding:0 !important;
}

.mat-button-toggle-disabled {
    pointer-events: auto !important;
    color: var(--mat-legacy-button-toggle-disabled-state-text-color);
    background-color: var(--mat-legacy-button-toggle-disabled-state-background-color);
    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: var(--mat-legacy-button-toggle-disabled-state-text-color);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: transparent !important;
}

.Mat-formContainer{
    width:220px;
}


.mat-mdc-form-field-focus-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    background-color: none !important
}

.mat-form-field-underline {
  display: none !important;
}

.mdc-line-ripple{
    display:none !important;
}

.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper{
    cursor: pointer;
    top: 5px !important;
}

.mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important; 
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay{
    opacity:0 !important;
}

.mat-mdc-select-arrow svg {
    fill: #999 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mat-mdc-text-field-wrapper {
    width: 100%;
    z-index: 0;
}


.role-select{
    padding:6px 10px;
    border:2px solid #006633 !important;
    border-top-left-radius:3px !important;
    border-top-right-radius:3px !important;
    border-bottom-left-radius:3px !important;
    border-bottom-right-radius:3px !important;
}
.role-select:focus-visible{
    border:2px solid #006633 !important;
}
.role-select:focus{
    border:2px solid #006633 !important;
    border-top-left-radius:3px !important;
    border-top-right-radius:3px !important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after, 
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
    color: #006633 !important;
}




.mat-ripple .mat-mdc-option-ripple .mat-mdc-focus-indicator{
    background:#006633 !important;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transform-origin: top center;
    border: 2px solid #006633 !important;
    padding:10px;
}


.cdk-overlay-pane:has(.mat-mdc-select-panel) { 
    position:relative;
    top:118px !important;
    border-bottom-right-radius:2px;
    border-bottom-left-radius:2px;



}


.mdc-text-field{
    padding:0px 0px !important;
}


div.mat-mdc-select-panel {
    box-shadow: var(--mat-select-container-elevation-shadow);
}


.mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 0 !important; 
    padding-bottom: 0 !important;
}

.mat-mdc-form-field-infix {
    max-width:100%;
    width:200px !important;
    min-height:0 !important; 
    padding-top: 0!important; 
    padding-bottom: 0 !important
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
 /* background: none !important;*/
}

div


.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: #3D4551 !important;
    font-weight:bold;
}


.role-select.mat-select-panel-open .mat-mdc-select-arrow-wrapper {
    display: none;
  }


.mat-mdc-select-arrow {
    display: none;
  }


.mat-form-field-appearance-fill .mdc-text-field--no-label .mat-mdc-select-arrow-wrapper {
    transform: none;
}

.mat-mdc-option {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-user-select: none;
    user-select: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: var(--mat-option-label-text-color);
    font-family: var(--mat-option-label-text-font);
    line-height: var(--mat-option-label-text-line-height);
    font-size: var(--mat-option-label-text-size);
    letter-spacing: var(--mat-option-label-text-tracking);
    font-weight: var(--mat-option-label-text-weight);
    min-height: 48px;
}

.mat-mdc-option .mdc-list-item__primary-text {
    white-space: normal;
    font-size:14px !important;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    font-family: inherit;
    text-decoration: inherit;
    text-transform: inherit;
    margin-right: auto;
}

.mat-mdc-list-option .mdc-radio .mdc-radio__native-control:enabled+
.mdc-radio__background .mdc-radio__inner-circle {
    border-color: #3D4551!important; 
     border-color: var( #000000)!important;
}

.mat-h3, .mat-subtitle-1, .mat-typography .mat-h3, .mat-typography .mat-subtitle-1, .mat-typography h3 {
    font: 400 16px / 28px Roboto, sans-serif;
    letter-spacing: .009375em;
    margin: 0 0 5px !important;
}

.custom-radio-button .mdc-radio__outer-circle {
    border-color: #3D4551 !important; 
}

.mat-mdc-list-option .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
    border:#3d4551 !important;
    background-color:#3D4551 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, 
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, 
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color:#3d4551 !important;
    background-color:#3D4551 !important;
  
}


.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background{
    color: var(--mdc-checkbox-selected-checkmark-color);
  
}
.mdc-checkbox__background{
  /*  outline:2px solid green !important;*/
}


.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
    /*  outline:2px solid green !important;
  opacity:transparent !important;
    background-color: transparent !important;*/
    
}



.mat-mdc-checkbo .mdc-checkbox__background {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.mdc-checkbox:hover .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple {
    opacity:transparent !important;
    background-color: transparent !important;
    display:none !important;
}


.mat-checkbox .mdc-checkbox__ripple{
    background-color:#63a007 !important;
    opacity:1;
    box-shadow: 1px 1px 2px 0px #233012 !important;
    
}


  
  .mat-checkbox-checked .mat-checkbox-ripple {
    /*border: 2px solid green !important;  Add a border when checked */
  }

.mdc-checkbox:active .mdc-checkbox__native-control~.mdc-checkbox__ripple{
    opacity:transparent!important;
    background-color: transparent !important;
    display:none !important;
}
.mat-mdc-list-option .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background
 .mdc-radio__outer-circle {
    border-color: #000 !important; 
    
}





.ngx-pagination .current {
    padding: .1875rem .625rem;
    background: #006633 !important;
    outline:transparent!important; 
    color: #fefefe;
    cursor: default;
    border-radius:5px !important;
}

.ngx-pagination .ng-star-inserted a:link{
    outline:transparent!important;   
   }

.ngx-pagination .ng-star-inserted a:focus-visible{
    outline: 1px solid #006633 !important;
    
}
.ngx-pagination .ng-star-inserted a:active,.ng-star-inserted{
 outline:transparent!important;   
}

.ngx-pagination {
    padding-left: 0 !important;
}
/*====================================*/
/*   Media Queries                    */
/*====================================*/



@media screen and (max-width: 1263px){
    .containerbtn{
        font-size: 12px !important;
    }
    

    /*for tooltip*/
    .mat-mdc-tooltip{
        position:absolute;
        top:-4px;
        left:calc(85% - 95%);
        right:50%;
        border:2px solid #000;
        background:#000;
        border-radius:4px;
        -webkit-font-smoothing:antialiased !important;
        
    } 
    
    h2.usa-main-page-header{
        color:#3D4551 !important;
        margin-bottom:25px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
    }
    
    .statusForm{
        width:300px !important;
        margin:0 auto;
   
    }

    .formBtns{
        display:flex;
        width:300px;
    }

    #forestCode > div, 
    #regionCode > div, 
    #districtCode > div{
    width:100%;
    max-width:100% !important;
    height:50px;
    }

    
}


@media (min-width: 768px) and (max-width:1024px){

    .mat-mdc-tooltip{
        position:absolute;
        top:4px;
        left:calc(30% - 100%)!important;
        right:50%;
        border:2px solid #000;
        background:#000;
        border-radius:4px;
        -webkit-font-smoothing:antialiased !important;
    }  

    h2.usa-main-page-header{
        color:#3D4551 !important;
        margin-bottom:25px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
    }

    .statusForm{
        width:300px !important;
        margin:0 auto;
    }

    .formBtns{
        display:flex;
        width:300px;
    }

    #forestCode > div, 
    #regionCode > div, 
    #districtCode > div{
        width:100%;
        max-width:100% !important;
        height:50px;
    }
    

}



@media (min-width: 540px) and (max-width:760px){

    .mat-mdc-tooltip{
        position:absolute;
        top:-4px;
        left:calc(58% - 100%);
        right:50%;
        border:2px solid #000;
        background:#000;
        border-radius:4px;
        -webkit-font-smoothing:antialiased !important;
    } 
    h2.usa-main-page-header{
        color:#3D4551 !important;
        margin-bottom:25px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
    }

    .statusForm{
        width:300px !important;
        margin:0 auto;
   
    }

    .formBtns{
        display:flex;
        width:300px;
    }

    #forestCode > div, 
    #regionCode > div, 
    #districtCode > div{
        width:100%;
        max-width:100% !important;
        height:50px;
    }
    

}


@media screen and (max-width: 375px){
    .containerbtn{
        font-size: 1.85vh !important;
    }
    

    /*for tooltip*/
    .mat-mdc-tooltip{
        position:absolute;
        top:-40px !important;
        left:calc(98% - 100%);
        right:50%;
        border:2px solid #000;
        background:#000;
        border-radius:4px;
        -webkit-font-smoothing:antialiased !important;
       
    }

    h2.usa-main-page-header{
        color:#3D4551 !important;
        margin-bottom:25px;
        font-family: Robot, sans-serif !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }

    .statusForm{
        width:300px !important;
        margin:0 auto;
   
    }

    .formBtns{
        display:flex;
        width:300px;
    }
    
}

@media screen and (max-width: 324px){
    .title[_ngcontent-ng-c1723005704] {
        text-indent: 0px;
    }
    #main-accordion-wrapper{
        box-sizing: border-box;
        padding-left:0 !important;
        padding-right:0 !important;
        width:100%;
        min-width:320px !important;
    }
    .estimator{
        box-sizing: border-box;
        position: sticky;
        top: 253px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 18px 0px;
        margin-top: 7px;
        gap: 42px;
        background: #FFFFFF;
        border: 10px solid #9BB672;
        box-shadow: 0px 4px 16px rgba(76, 85, 91, 0.2);
        border-radius: 16px;
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-basis: calc(-99900% + 379620px);
        min-width: 300px !important;
        max-height: 100%;
        overflow: hidden;
    }

    .usa-input, .usa-select,.usa-select, select{
        width:280px !important;
        max-width: 280px !important;
    }
    .containerbtn{
        font-size: 1.48vh !important;
    }
    
    h2.usa-main-page-header{
        color:#3D4551 !important;
        margin-bottom:25px;
        font-family: 'Roboto', sans-serif !important;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
    }

    .statusForm{
        width:300px;
        margin:0 auto;
    }

    .mat-mdc-tooltip{
        position:absolute;
        top:-5px !important;
        left:calc(75% - 100%);
        right:50%;
        border:2px solid #000;
        background:#000;
        border-radius:4px;
        -webkit-font-smoothing:antialiased !important;
    } 

    
}


span{
    text-decoration:none;
}

.mat-mdc-slider .mdc-slider__thumb-knob{
    background-color:rgb(83, 130, 0) !important;
    border-color:#538200 !important;
}
.mat-mdc-slider .mdc-slider__track--inactive{
    background-color:#fff !important;
    border:1px solid #538200;
}
.mat-mdc-slider .mdc-slider__track--active_fill{
    border-color:#538200 !important;
}

.mat-mdc-slider 
.mdc-slider__value-indicator{
    background-color:rgb(83, 130, 0) !important;
}

.mat-mdc-slider 
.mdc-slider__value-indicator::before {
    border-top-color: #538200 !important;
}


.mdc-slider .mdc-slider__value-indicator::before{
    border-left: 6px solid rgba(83, 130, 0, 0) !important;
    border-right: 6px solid rgba(83, 130, 0, 0) !important;
    border-top: 6px solid;
    bottom: -5px;
    content: "";
    height: 0;
    left: 50%;
    left: var(--slider-value-indicator-caret-left, 50%);
    position: absolute;
    right: var(--slider-value-indicator-caret-right);
    transform: translateX(-50%);
    transform: var(--slider-value-indicator-caret-transform, translateX(-50%));
    width: 0;
}

html {
    height: 100%; 
}

body { 
    height:100%;
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}


.usa-table thead td, .usa-table thead th {
    background-color: #F3F3F3 !important;
    color: #3D4551 !important;
  }
.usa-table td, .usa-table tbody th{
    font-family: 'Roboto', sans-serif!important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.usa-table thead th {
    font-family: 'Roboto', sans-serif!important;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

  caption{
    caption-side: top !important;
  }

  